// src/redux/codeSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

// Define a type for the food item
interface Item {
  _id: string;
  images: string[]; // Array of image URLs
  title: string;
  price: string;
  category: string;
  description: string;
}

// Define a type for the slice state
interface ItemState {
  allItems: Item[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

// Define the initial state using that type
const initialState: ItemState = {
  allItems: [],
  status: "idle",
  error: null,
};

// Async thunk to fetch the foods from the server
export const fetchItems = createAsyncThunk<Item[], void>(
  "foods/fetchItems",
  async () => {
    const response = await axios.get(`${process.env.REACT_APP_GET_ALL_ITEMS}`);
    let data = await response.data;
    let allItems = data.items;

    return allItems;
  }
);

const allItemSlice = createSlice({
  name: "items",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItems.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchItems.fulfilled, (state, action: PayloadAction<Item[]>) => {
        state.status = "succeeded";
        state.allItems = action.payload;
      })
      .addCase(fetchItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message || null;
      });
  },
});

export default allItemSlice.reducer;
