import React from "react";
import FoodColumn from "./FoodColumn";
import { useSelector } from "react-redux";
import { RootState } from "../redux/Store";

function PopularDrinks() {
  const State = useSelector((state: RootState) => state);
  const { allItems: items } = State.AllItem;
  const drinks = items.filter((item) => item.category === "drink");

  return (
    <div className="my-10 mt-24 w-full text-center font-serif">
      <div className="md:mx-[10rem] my-10 py-4 px-10 bg-[#df2020] rounded-md">
        <div className="items-center text-white mx-auto">
          <h1 className="text-xl font-bold">Popular Drinks</h1>
        </div>
      </div>

      {/* Map over the items array to display the popular food items */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-12 lg:gap-x-8 mt-12 mx-5 lg:mx-16">
        {drinks.map((item) => (
          <FoodColumn key={item._id} item={item} />
        ))}
      </div>
    </div>
  );
}

export default PopularDrinks;
