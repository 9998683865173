import React from "react";
import { GiHamburger } from "react-icons/gi";
import { RiDrinks2Fill } from "react-icons/ri";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function OptionRow() {
  return (
    <div className="md:pt-10 mt-[-5rem] pb-[4rem] md:pb-[7rem] md:mt-[5vh]">
      <div className="flex flex-wrap md:gap-10 gap-5 items-center justify-center max-[450px]:grid max-[450px]:grid-cols-2 md:px-20 p-2">
        <Link to={"/foods"}>
          <motion.div
            whileHover={{
              translateY: -12,
            }}
            transition={{
              duration: 0.2,
            }}
            className="flex items-center gap-1 bg-[#fde4e4] py-5 px-2 md:px-12 font-semibold rounded hover:cursor-pointer shadow-lg border"
          >
            <span className="bg-[#df2020] md:p-4 p-2 rounded-[50%]">
              <GiHamburger className="text-white text-xl md:text-4xl" />
            </span>
            Meals
          </motion.div>
        </Link>
        <Link to={"/drinks"}>
          <motion.div
            whileHover={{
              translateY: -12,
            }}
            transition={{
              duration: 0.2,
            }}
            className="flex items-center gap-1 bg-[#fde4e4] py-5 px-2 md:px-12 font-semibold rounded hover:cursor-pointer shadow-lg border border-gray-100"
          >
            <span className="bg-[#df2020] md:p-4 p-2 rounded-[50%]">
              <RiDrinks2Fill className="text-white text-xl md:text-4xl" />
            </span>
            Drinks
          </motion.div>
        </Link>
      </div>
    </div>
  );
}

export default OptionRow;
